"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("@coreui/react");
const internal_portal_components_1 = require("internal-portal-components");
const portal_api_1 = require("portal-api");
const utils_1 = require("../../../utils");
const LocationDetails = ({ hasPermLocationStatus, location, }) => {
    const [triggerTurnOnAff] = (0, portal_api_1.usePostInternalPortalV1LocationsLocationIdTurnOnAffMutation)();
    const [triggerTurnOffAff] = (0, portal_api_1.usePostInternalPortalV1LocationsLocationIdTurnOffAffMutation)();
    const onToggleLender = async (lenderName, value) => {
        if (location === null) {
            throw Error('Location not found');
        }
        if (lenderName === 'AFF') {
            return value
                ? triggerTurnOnAff({
                    locationId: location.locationId,
                    body: { managerEmail: (0, utils_1.getUserEmail)() },
                })
                : triggerTurnOffAff({
                    locationId: location.locationId,
                });
        }
        else {
            throw Error('Lender not supported to turn on');
        }
    };
    return ((0, jsx_runtime_1.jsxs)("div", { className: "flex-wrap-1200 space-between col-gap-20", children: [(0, jsx_runtime_1.jsx)("div", { className: "location-subcard", children: (0, jsx_runtime_1.jsx)(internal_portal_components_1.LocationStatus, { locationId: location.locationId, locationName: location.name, isDisabled: !hasPermLocationStatus }) }), (0, jsx_runtime_1.jsx)("div", { className: "location-subcard", children: (0, jsx_runtime_1.jsxs)(react_1.CCard, { className: "location-box box-stack applicant-info-container", children: [(0, jsx_runtime_1.jsx)(react_1.CCardTitle, { className: "w100", as: "h4", children: "Lenders" }), location?.lenders.map((lender) => ((0, jsx_runtime_1.jsx)(internal_portal_components_1.LenderDisplay, { lenderName: lender.name ?? '', status: lender.status ?? '', onToggle: onToggleLender, configDetails: lender.configDetails, pricingTier: lender.pricingTier, locationId: location.locationId, isDisabled: !hasPermLocationStatus }, lender.id + '-' + location.locationId)))] }) }), (0, jsx_runtime_1.jsx)("div", { className: "location-subcard", children: (0, jsx_runtime_1.jsxs)(react_1.CCard, { className: "location-box box-stack applicant-info-container", children: [(0, jsx_runtime_1.jsx)(react_1.CCardTitle, { className: "w100", as: "h4", children: "Features" }), (0, jsx_runtime_1.jsx)(internal_portal_components_1.LocationFeatures, { isDisabled: !hasPermLocationStatus, location: location })] }) })] }));
};
exports.default = LocationDetails;
