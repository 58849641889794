"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const lodash_1 = require("lodash");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const react_router_dom_1 = require("react-router-dom");
const redux_1 = require("redux");
const redux_flash_1 = require("redux-flash");
const components_1 = require("components");
const config_1 = require("config");
const merchant_portal_components_1 = require("merchant-portal-components");
const utils_1 = require("utils");
const SendReceiptModal = ({ isOpen, setIsOpen, onRequestClose, receiptOptions, email, phone, flashSuccessMessage, flashErrorMessage, name, createReceipt, transactionId, sendReceipt, receiptUrl, alphaeonAccountNumber, affAutoSend = true, imprintAutoSend = true, isResend = false, receiptData = null, daysUntilAuthExpiration, refetchReceiptsData = lodash_1.noop, }) => {
    const [communicationMethod, setCommunicationMethod] = (0, react_1.useState)('');
    const [submitting, setSubmitting] = (0, react_1.useState)(false);
    const [confirmSMS, setConfirmSMS] = (0, react_1.useState)(false);
    const [radioVal, setRadioVal] = (0, react_1.useState)(null);
    const [formSubmitted, setFormSubmitted] = (0, react_1.useState)(false);
    const [sentTo, setSentTo] = (0, react_1.useState)(null);
    const [fieldOptions, setFieldOptions] = (0, react_1.useState)([]);
    const optionsToFieldMap = {
        sms: { key: 'Phone', value: 'sms' },
        email: { key: 'Email', value: 'email' },
    };
    const history = (0, react_router_dom_1.useHistory)();
    (0, react_1.useEffect)(() => {
        if (receiptUrl &&
            ((receiptUrl.indexOf(config_1.AFF_DOMAIN) >= 0 && affAutoSend) ||
                (receiptUrl.indexOf(config_1.IMPRINT_DOMAIN) >= 0 && imprintAutoSend))) {
            setIsOpen(true);
            setFormSubmitted(true);
            setSentTo(email);
        }
    }, [receiptUrl]);
    (0, react_1.useEffect)(() => {
        const fields = receiptOptions?.options
            .filter((option) => {
            return option.receipt_type === 'email' || option.receipt_type === 'sms';
        })
            .map((option) => optionsToFieldMap[option.receipt_type])
            .sort((a) => (a.key === 'Email' ? 1 : -1));
        setRadioVal(fields[0].value);
        setCommunicationMethod(fields[0].value);
        setFieldOptions(fields);
    }, []);
    const handleCommunicationMethod = (e) => {
        const tmpVal = e.target.value;
        setRadioVal(tmpVal);
        setCommunicationMethod(tmpVal);
        // dont display the checkbox if email is selected
        if (tmpVal === 'email') {
            setConfirmSMS(false);
        }
    };
    const handleCloseLocal = () => {
        if (formSubmitted === true &&
            isOpen === true &&
            history.location.pathname !==
                `${config_1.ACCOUNT_DETAIL_URL}${alphaeonAccountNumber}`) {
            window.location.href = '/merchant';
        }
        else {
            onRequestClose();
        }
    };
    const handleSendReceipt = async () => {
        setSubmitting(true);
        const communicationMethodOutput = communicationMethod === 'sms' ? 'SMS' : 'Email';
        try {
            const respCreateReceipt = isResend
                ? receiptData
                : await createReceipt(Number(transactionId), communicationMethod);
            const postPhone = communicationMethod === 'sms' ? phone : undefined;
            const postEmail = communicationMethod === 'email' ? email : undefined;
            const jsonBase64 = encodeURIComponent(btoa(JSON.stringify({
                receipt_id: respCreateReceipt.uuid,
                type: communicationMethod,
                email: email,
                phone: phone,
            })));
            const respSendReceipt = await sendReceipt(Number(transactionId), respCreateReceipt.receiptId, receiptUrl
                ? `${receiptUrl}`
                : `${config_1.CONSUMER_APP_URL}${config_1.PATH.CREDIT_PORTAL_RECEIPT}/${jsonBase64}`, postPhone, postEmail);
            refetchReceiptsData();
            if (respSendReceipt.sent) {
                setFormSubmitted(true);
                setSentTo(respSendReceipt.sentTo);
                flashSuccessMessage(`${communicationMethodOutput} has been successfully sent.`);
            }
            else {
                setSubmitting(false);
                handleCloseLocal();
                flashErrorMessage(`${communicationMethodOutput} failed to send.`);
            }
        }
        catch (e) {
            setSubmitting(false);
            onRequestClose();
            let errMsg = '';
            if (e.response) {
                errMsg = e.response.message;
            }
            else {
                errMsg = `${communicationMethodOutput} failed to send.`;
            }
            (0, utils_1.reportError)('flashError', {
                componentName: 'SendReceiptModal',
                funcName: 'handleSendReceipt',
                errType: communicationMethodOutput,
                errURL: window.location.pathname,
                errMsg: errMsg,
            }, e);
            handleCloseLocal();
            flashErrorMessage(errMsg);
        }
    };
    const onGoToDashboard = () => {
        window.location.href = '/merchant'; // hard refresh
    };
    const properties = {
        handleCommunicationMethod: handleCommunicationMethod,
        setConfirmSMS: setConfirmSMS,
        formSubmitted: formSubmitted,
        name: name,
        sentTo: sentTo,
        communicationMethod: communicationMethod,
        onGoToDashboard: onGoToDashboard,
        onRequestClose: onRequestClose,
        confirmSMS: confirmSMS,
        fields: fieldOptions,
        email: email,
        phone: phone,
        radioVal: radioVal,
        handleSendReceipt: handleSendReceipt,
        submitting: submitting,
        alphaeonAccountNumber: alphaeonAccountNumber,
        daysUntilAuthExpiration: daysUntilAuthExpiration,
    };
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsx)(components_1.Modal, { isOpen: isOpen, setIsOpen: setIsOpen, onRequestClose: handleCloseLocal, modalTestId: "send-receipt-modal", closeBtnTestId: "send-receipt-close-button", contentClassName: "alert-modal", children: receiptUrl ? ((0, jsx_runtime_1.jsx)(merchant_portal_components_1.SendContractModalContent, { ...properties })) : ((0, jsx_runtime_1.jsx)(merchant_portal_components_1.SendReceiptModalContent, { ...properties })) }) }));
};
const mapDispatchToProps = {
    flashSuccessMessage: redux_flash_1.flashSuccessMessage,
    flashErrorMessage: redux_flash_1.flashErrorMessage,
};
exports.default = (0, redux_1.compose)((0, react_redux_1.connect)(null, mapDispatchToProps))(SendReceiptModal);
