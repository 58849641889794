"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
const config_1 = require("config");
function formatProgramProductType(productType) {
    if ((0, lodash_1.isNil)(productType))
        return '';
    return (config_1.PROGRAM_PRODUCT_TYPE_MAP[productType] ?? '');
}
exports.default = formatProgramProductType;
