"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_router_dom_1 = require("react-router-dom");
const components_1 = require("components");
function PreQualificationDeclinedImprintOnlyPage() {
    const location = (0, react_router_dom_1.useLocation)();
    return ((0, jsx_runtime_1.jsx)("div", { className: "declined-container", children: (0, jsx_runtime_1.jsxs)("div", { className: "content", children: [(0, jsx_runtime_1.jsx)(components_1.H1, { children: "Credit Application" }), (0, jsx_runtime_1.jsx)(components_1.H2, { variant: "title-extra-large", className: "mar-t-20", children: "Thank you for your interest in Alphaeon" }), (0, jsx_runtime_1.jsxs)(components_1.H2, { variant: "title-extra-large", className: "mar-t-20", children: ["We were unable to qualify you. You will receive an email at", ' ', location.state.email, " within 30 days with the details regarding this decision."] })] }) }));
}
exports.default = PreQualificationDeclinedImprintOnlyPage;
