"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const components_1 = require("components");
const config_1 = require("config");
function ApplicationNotFound() {
    return ((0, jsx_runtime_1.jsx)("div", { className: "max-width-800 pad-y-40 text-center mar-auto", children: (0, jsx_runtime_1.jsxs)("div", { className: "main-content min-height-600 mob-pad-x-40", children: [(0, jsx_runtime_1.jsx)("header", { children: (0, jsx_runtime_1.jsx)(components_1.H1, { children: "No Application Found" }) }), (0, jsx_runtime_1.jsx)(components_1.P, { children: "Thank you for your interest in the Alphaeon Credit Card." }), (0, jsx_runtime_1.jsx)(components_1.P, { children: "We were unable to find any open applications." }), (0, jsx_runtime_1.jsx)("br", {}), (0, jsx_runtime_1.jsx)("br", {}), (0, jsx_runtime_1.jsx)("br", {}), (0, jsx_runtime_1.jsx)("div", { className: "next-step", children: (0, jsx_runtime_1.jsxs)(components_1.P, { children: ["You can start a new application by clicking", ' ', (0, jsx_runtime_1.jsx)(react_router_dom_1.Link, { className: "btn-link", to: config_1.CREDIT_PORTAL_URL, children: "here" }), "."] }) })] }) }));
}
exports.default = (0, react_1.memo)(ApplicationNotFound);
