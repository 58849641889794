"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const lp_components_1 = require("lp-components");
const lp_form_1 = require("lp-form");
const react_redux_1 = require("react-redux");
const redux_1 = require("redux");
function PlanSelectionForm({ handleSubmit, submitting, lenderSettings, }) {
    const showSaleInText = lenderSettings?.lenderProductType != 'LTO';
    const SALE_TEXT = showSaleInText ? 'COMPLETE SALE' : 'COMPLETE TRANSACTION';
    return ((0, jsx_runtime_1.jsx)("div", { className: "plan-selection__form", children: (0, jsx_runtime_1.jsx)("form", { noValidate: true, onSubmit: handleSubmit, children: (0, jsx_runtime_1.jsx)(lp_components_1.SubmitButton, { invalid: submitting, className: "button-warn", submitting: submitting, children: SALE_TEXT }) }) }));
}
function mapStateToProps() {
    return {};
}
const mapDispatchToProps = {};
exports.default = (0, redux_1.compose)((0, lp_form_1.lpForm)({
    name: 'planSelectionForm',
    constraints: {},
}), (0, react_redux_1.connect)(mapStateToProps, mapDispatchToProps))(PlanSelectionForm);
