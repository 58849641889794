"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useNavigationTracker = exports.useBackButtonTracker = exports.usePageRefreshTracker = void 0;
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const AnalyticsContext_1 = require("../context/AnalyticsContext");
const usePageRefreshTracker = () => {
    const { analytics } = (0, react_1.useContext)(AnalyticsContext_1.AnalyticsContext);
    const location = (0, react_router_dom_1.useLocation)();
    (0, react_1.useEffect)(() => {
        const trackPageRefresh = () => {
            const navEntries = performance.getEntriesByType('navigation');
            if (navEntries.length > 0 &&
                navEntries[0].type === 'reload') {
                analytics?.track('page_refresh', {
                    page: location.pathname,
                    timestamp: new Date().toISOString(),
                });
            }
        };
        trackPageRefresh();
    }, [analytics, location.pathname]);
};
exports.usePageRefreshTracker = usePageRefreshTracker;
/**
 * Hook to track back button clicks
 */
const useBackButtonTracker = () => {
    const { analytics } = (0, react_1.useContext)(AnalyticsContext_1.AnalyticsContext);
    const location = (0, react_router_dom_1.useLocation)();
    (0, react_1.useEffect)(() => {
        const handlePopState = () => {
            analytics?.track('back_button', {
                page: location.pathname,
                timestamp: new Date().toISOString(),
            });
        };
        window.addEventListener('popstate', handlePopState);
        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [analytics, location.pathname]);
};
exports.useBackButtonTracker = useBackButtonTracker;
/**
 * Combined hook for tracking both page refreshes and back button clicks
 */
const useNavigationTracker = () => {
    usePageRefreshTracker();
    useBackButtonTracker();
};
exports.useNavigationTracker = useNavigationTracker;
