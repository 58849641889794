"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("@coreui/react");
const react_2 = require("react");
const react_color_1 = require("react-color");
const react_router_dom_1 = require("react-router-dom");
const portal_api_1 = require("portal-api");
const StoreLinkCampaigns = ({ location, hasPermStoreLinkCampaigns, }) => {
    const { id } = (0, react_router_dom_1.useParams)();
    const initialSize = 680;
    const initialColor = '9B1B3C';
    const initialLogo = false;
    const [color, setColor] = (0, react_2.useState)(initialColor);
    const [logo, setLogo] = (0, react_2.useState)(initialLogo);
    const imgEl = (0, react_2.useRef)(null);
    const handleColorChangeComplete = (color) => {
        setColor(color.hex);
    };
    const { data: platformLinkCampaigns, isFetching: isLoadingPlatformLinkCampaigns, } = (0, portal_api_1.useGetInternalPortalV1PlatformCampaignsStoreLinkCampaignsQuery)({
        locationId: Number(id),
    });
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: hasPermStoreLinkCampaigns &&
            platformLinkCampaigns &&
            platformLinkCampaigns.length > 0 ? ((0, jsx_runtime_1.jsxs)("div", { className: "mt-3 d-flex flex-row align-items-start justify-content-center", children: [(0, jsx_runtime_1.jsx)("div", { className: "me-4", children: (0, jsx_runtime_1.jsx)("img", { ref: imgEl, src: `${process.env.REACT_APP_API_URL}/qr_code/store_link_campaign/${location.adsStoreNumber}?size=${initialSize}&color=${encodeURIComponent(color)}&logo=${logo}`, alt: "qrCode", style: { maxWidth: '680px', width: '100%' } }) }), (0, jsx_runtime_1.jsxs)("div", { className: "qr-code-selection-option", children: [(0, jsx_runtime_1.jsxs)("div", { className: "mb-5", children: [(0, jsx_runtime_1.jsx)("label", { className: "mb-3", htmlFor: "logoToggle", children: "Show Logo" }), (0, jsx_runtime_1.jsx)(react_1.CFormSwitch, { id: "logoToggle", className: "toggle-button", checked: logo, onChange: () => setLogo(!logo) })] }), (0, jsx_runtime_1.jsxs)("div", { className: "mb-5 mt-5", children: [(0, jsx_runtime_1.jsx)("label", { className: "mb-3", htmlFor: "colorPicker", children: "Color" }), (0, jsx_runtime_1.jsx)(react_color_1.SketchPicker, { color: color, className: "selector", onChangeComplete: handleColorChangeComplete })] })] })] })) : ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: isLoadingPlatformLinkCampaigns ? ((0, jsx_runtime_1.jsx)("div", { className: "d-flex justify-content-center", children: (0, jsx_runtime_1.jsx)(react_1.CSpinner, { color: "primary" }) })) : ('No Store Link Campaigns found') })) }));
};
exports.default = StoreLinkCampaigns;
